<template>
  <v-container fluid :class="$vuetify.breakpoint.smAndDown ? 'py-9' : 'pa-9'">
    <!-- save changes -->
    <div class="d-flex justify-end mb-5">
      <v-btn
        :color="color.secondary"
        :ripple="false"
        :fab="$vuetify.breakpoint.smAndDown"
        :small="$vuetify.breakpoint.smAndDown"
        :loading="buttonLoader"
        :disabled="disabledButton"
        :fixed="$vuetify.breakpoint.smAndDown"
        @click="validateEmail"
        class="rounded-pill font-weight-bold text-capitalize"
        :class="$vuetify.breakpoint.smAndDown ? 'saveButtom' : ''"
      >
        <span v-if="!$vuetify.breakpoint.smAndDown">Guardar cambios</span>
        <v-icon v-else> mdi-content-save-outline </v-icon>
      </v-btn>
    </div>
    <!-- end save changes -->

    <v-row>
      <!-- profile data -->
      <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
        <v-card outlined class="pa-6 py-10 pb-3">
          <span
            class="labelCard pa-0 px-1 ma-0"
            :style="`color:${color.primaryVariant}`"
          >
            Datos de identificación para el perfil
          </span>
          <v-row class="py-9">
            <v-col
              cols="12"
              class="py-0 pb-3 ma-0"
              v-for="(item, i) in organizationData"
              :key="i"
            >
              <v-text-field
                :background-color="color.background"
                rounded
                :disabled="false"
                dense
                class="ma-0 pa-0"
                height="40"
                :rules="item.rules || []"
                :label="item.label"
                v-model="item.value"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" class="d-flex flex-row-reverse">
              <v-btn
                text
                elevation="1"
                @click="passwordDialog = true"
                class="text-capitalize rounded-pill"
                style="font-weight: 800"
              >
                Cambiar contraseña
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <!-- end profile data -->

      <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
        <!-- Account integration -->
        <v-card outlined class="pa-6 pt-5 mb-7">
          <span
            class="labelCard pa-0 px-1 ma-0"
            :style="`color:${color.primaryVariant}`"
          >
            Integración de mi cuenta bancaria
          </span>
          <v-card-subtitle
            class="py-0 pb-4"
            style="line-height: 18px; font-weight: 500"
          >
            Esta es la cuenta en la que recibirás el dinero proveniente de la
            venta de tus eventos o membresías
          </v-card-subtitle>
          <v-card-actions class="d-flex justify-space-between align-center">
            <span
              :style="{ color: color.primaryVariant }"
              class="font-weight-bold"
            >
              <v-icon :color="color.primaryVariant"
                >mdi-help-circle-outline</v-icon
              >
              Ver tutorial
            </span>
            <v-btn
              v-if="
                (user.accountSetup != 'VALIDATED' || !user.bankInformation) &&
                !user.expressAccount
              "
              :color="color.primary"
              :loading="bankAccountLoader"
              style="font-weight: 800"
              @click="connectAccount()"
              :disabled="
                !user.permissions.Account || !user.permissions.Account.update
              "
              class="wDarker--text text-capitalize rounded-pill px-3"
            >
              Iniciar integración
            </v-btn>

            <div v-else class="d-flex flex-column">
              <p
                class="font-weight-bold text-end"
                v-if="
                  user.bankInformation.bankName && user.bankInformation.last4
                "
              >
                <span>Banco: </span
                ><span :style="{ color: color.primary }">{{
                  user.bankInformation.bankName
                }}</span>
                <br />
                <span>Últimos 4 dígitos: </span
                ><span :style="{ color: color.primary }">{{
                  user.bankInformation.last4
                }}</span>
              </p>
              <v-btn
                v-if="!hasStripeConfig && user.stripeAccountType === 'STANDARD'"
                :color="color.primary"
                style="font-weight: 800"
                class="wDarker--text text-capitalize rounded-pill px-3"
                href="https://dashboard.stripe.com/settings/payments"
                target="_blank"
              >
                Integración SPEI
              </v-btn>
              <p v-else style="text-align: end; font-weight: 700">
                SPEI
                <v-icon color="green"> mdi-check-circle </v-icon>
              </p>
            </div>
          </v-card-actions>
          <v-row justify="end">
            <v-btn
              v-if="user.expressAccount"
              :color="color.primary"
              style="font-weight: 800"
              @click="getExpressLink()"
              :loading="expressLinkLoader"
              :disabled="
                !user.permissions.Account || !user.permissions.Account.update
              "
              class="wDarker--text text-capitalize rounded-pill px-3"
            >
              Actualizar mi cuenta bancaria
            </v-btn>
          </v-row>
        </v-card>
        <!-- end account integration -->

        <!-- profile logo image -->
        <v-card outlined class="pa-6 pt-9 pb-11">
          <span
            class="labelCard pa-0 px-1 ma-0"
            :style="`color:${color.primaryVariant}`"
          >
            Cargar logotipo para el perfil
          </span>

          <v-row>
            <v-col cols="12" class="d-flex justify-center">
              <dropImageUploaderVue
                @update="handleImageUpdate"
                :currentUrl="userProfilePicture"
              />
            </v-col>
          </v-row>
        </v-card>
        <!-- end profile logo image -->
      </v-col>
      <v-col :cols="12">
        <!-- branch documents (BRANCH's users only) -->
        <v-card
          outlined
          class="pa-6 mt-9 pb-11"
          v-if="user.role == 'BRANCH' && documentsInputs.length > 0"
        >
          <span
            class="labelCard pa-0 px-1 ma-0"
            :style="`color:${color.primaryVariant}`"
          >
            Documentos del capítulo
          </span>
          <v-card-subtitle
            class="py-0 pb-4"
            style="line-height: 18px; font-weight: 500"
          >
            Es necesario cargar correctamente los siguientes documentos para
            obtener la insignia como capítulo certificado
          </v-card-subtitle>

          <v-row>
            <v-col
              cols="4"
              v-for="(input, index) in documentsInputs"
              :key="index"
            >
              <FormInput
                :index="index"
                :field="input"
                :bgColor="color.background"
                @input="inputHandler"
              ></FormInput>
            </v-col>
          </v-row>
        </v-card>
        <!-- end branch documents -->
      </v-col>
    </v-row>

    <PersonalizedDialog
      :dialog="dialog"
      @save="updateAllData"
      @discard="dialog = false"
    >
      <template v-slot:alertName> Guardar cambios </template>
      <template v-slot:alertContent>
        <p class="title text-center">
          ¿Estas seguro que deseas cambiar tu correo, si es así recuerda que
          estarás cambiando tu usuario con el cual inicias sesión
        </p>
      </template>
      <template v-slot:button> OK </template>
    </PersonalizedDialog>

    <!-- change password dialog -->
    <v-dialog v-model="passwordDialog" max-width="500">
      <v-card
        color="white"
        max-height="370"
        @click:outside="closePasswordDialog"
        class="pa-7"
      >
        <span :style="{ color: color.primaryVariant }" class="font-weight-bold"
          >Cambiar contraseña de ingreso</span
        >
        <v-row class="my-4">
          <v-col
            cols="12"
            v-for="(item, i) in password"
            :key="i"
            class="py-0 pb-4"
          >
            <v-text-field
              background-color="#f8fbfc"
              rounded
              :error-messages="item.rules"
              dense
              class=""
              height="40"
              :type="item.show ? 'text' : 'password'"
              :label="item.label"
              v-model="item.value"
            >
              <v-icon
                slot="append"
                class="d-flex justify-center pt-2 align-center"
                @click="item.show = !item.show"
              >
                {{ item.show ? "mdi-eye" : "mdi-eye-off" }}
              </v-icon>
            </v-text-field>
          </v-col>
        </v-row>
        <v-card-actions
          class="d-flex pt-0 pb-3"
          :class="
            $vuetify.breakpoint.smAndDown ? 'justify-center' : 'justify-end'
          "
        >
          <v-btn
            @click="closePasswordDialog"
            class="px-8 font-weight-bold rounded-pill text-capitalize"
            elevation="0"
          >
            Cancelar
          </v-btn>
          <v-btn
            :disabled="validatePassword"
            @click="handleUpdatePwd"
            :color="color.primaryVariant"
            class="px-8 white--text rounded-pill text-capitalize"
            elevation="0"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end change password dialog -->
  </v-container>
</template>
<script>
import Endpoints from "@/share/Endpoints";
import dropImageUploaderVue from "../../../components/shared/dropImageUploader.vue";
import { validations } from "@/share/validations";
import FormInput from "../../../components/shared/FormInput.vue";
import { colors } from "@/constants/colors.js";
import { mapMutations, mapState, mapActions } from "vuex";
import PersonalizedDialog from "../../../components/shared/PersonalizedDialog";
import { max } from "moment";

export default {
  async mounted() {
    try {
      this.loading();
      await this.fetchOrganizationData();
      await this.getMethodPaymentActive();
    } catch (error) {
      console.log(error);
    } finally {
      this.loaded();
    }
  },
  components: {
    dropImageUploaderVue,
    PersonalizedDialog,
    FormInput,
  },
  data() {
    return {
      expressLinkLoader: false,
      dialog: false,
      emailChange: false,
      color: colors,
      userProfilePicture: "",
      tempData: [],
      disabledButton: true,
      buttonLoader: false,
      documentsInputs: [],
      passwordDialog: false,
      bankAccountLoader: false,
      hasStripeConfig: false,
      organizationData: [
        {
          value: "",
          label: "Nombre de la organización",
          id: "company",
          rules: validations.nameValidation({
            name: "nombre de la empresa",
            required: true,
          }),
        },
        {
          value: "",
          label: "Nombre del representante",
          id: "name",
        },
        {
          value: "",
          label: "Primer apellido del representante",
          id: "firstLastname",
        },
        {
          value: "",
          label: "Segundo apellido del representante",
          id: "secondLastname",
        },
        {
          value: "",
          label: "Teléfono",
          id: "phone",
          rules: validations.phoneValidation({
            required: false,
          }),
        },
        {
          value: "",
          label: "Correo electrónico",
          id: "email",
          rules: validations.emailValidation(),
        },
      ],
      oldEmail: "",
      password: [
        {
          id: "current",
          label: "Contraseña actual",
          value: "",
          show: false,
          rules: [],
        },
        {
          id: "new",
          label: "Nueva contraseña",
          value: "",
          show: false,
          rules: [],
        },
        {
          id: "conf_new",
          label: "Confirmar nueva contraseña",
          value: "",
          show: false,
          rules: [],
        },
      ],
    };
  },
  watch: {
    organizationData: {
      deep: true,
      handler(newQuestion) {
        if (this.oldEmail !== "" && this.oldEmail !== newQuestion[5].value) {
          console.log("cambio");
          this.emailChange = true;
        }
        this.oldEmail = newQuestion[5].value;
        const temporal = newQuestion.map((e) => e.value || "");

        if (JSON.stringify(temporal) == JSON.stringify(this.tempData)) {
          this.disabledButton = true;
        } else if (/^\d{10}$/.test(this.organizationData[4].value)) {
          this.disabledButton = false;
        }
      },
    },
  },
  computed: {
    ...mapState("user", ["user"]),
    ...mapState("loader", ["loaderVisible"]),

    validatePassword() {
      if (this.password[1].value !== this.password[2].value) {
        this.password.forEach((e) => {
          if (e.id === "conf_new") {
            e.rules.push("Las contraseñas deben ser iguales");
          } else if (e.id === "new") {
            e.rules.push(" ");
          }
        });
        return true;
      } else {
        this.password.forEach((e) => (e.rules = []));
      }

      let newPassword = this.password.find((e) => e.id === "new").value;
      let confirmPassword = this.password.find(
        (e) => e.id === "conf_new"
      ).value;

      if (newPassword === "" || confirmPassword === "") {
        return true;
      }

      if (newPassword.length < 8) {
        this.password.forEach((e) => {
          if (e.id === "conf_new") {
            e.rules.push("Las contraseñas deben tener al menos 8 caracteres");
          } else if (e.id === "new") {
            e.rules.push(" ");
          }
        });
        return true;
      }

      const hasUpperCase = /[A-Z]/.test(newPassword);
      const hasLowerCase = /[a-z]/.test(newPassword);
      const hasSpecialChar = /[!@#$%^&*()]/.test(newPassword);

      if (!hasUpperCase) {
        this.password.forEach((e) => {
          if (e.id === "conf_new") {
            e.rules.push(
              "La contraseña debe tener al menos una letra mayúscula"
            );
          } else if (e.id === "new") {
            e.rules.push(" ");
          }
        });
        return true;
      }

      if (!hasLowerCase) {
        this.password.forEach((e) => {
          if (e.id === "conf_new") {
            e.rules.push(
              "La contraseña debe tener al menos una letra minúscula"
            );
          } else if (e.id === "new") {
            e.rules.push(" ");
          }
        });
        return true;
      }

      if (!hasSpecialChar) {
        this.password.forEach((e) => {
          if (e.id === "conf_new") {
            e.rules.push(
              "La contraseña debe tener al menos un carácter especial"
            );
          } else if (e.id === "new") {
            e.rules.push(" ");
          }
        });
        return true;
      }

      return false;
    },

    /*passwordRule() {
      return [
        (v) => !!v || "La contraseña es requerida",
        (v) =>
          v.length >= 8 || "La contraseña debe tener al menos 8 caracteres",
        (v) =>
          /[A-Z]/.test(v) ||
          "La contraseña debe tener al menos una letra mayúscula",
        (v) =>
          /[a-z]/.test(v) ||
          "La contraseña debe tener al menos una letra minúscula",
        (v) =>
          /[^A-Za-z0-9]/.test(v) ||
          "La contraseña debe tener al menos un caracter especial",
      ];
    },*/
  },
  methods: {
    ...mapMutations("loader", ["loading", "loaded"]),
    ...mapMutations("notification", ["show"]),
    ...mapMutations("user", ["setUser"]),
    ...mapActions("user", ["login", "realoadUserData"]),
    validateEmail() {
      if (this.emailChange) {
        this.dialog = true;
        this.emailChange = false;
      } else {
        this.handleDataUpdate();
      }
    },
    updateAllData() {
      this.handleDataUpdate();
      this.dialog = false;
    },
    closePasswordDialog() {
      this.passwordDialog = false;
      this.password.forEach((e) => (e.value = ""));
    },
    getValueInOrgData(arrayObject, id) {
      let findIndex = arrayObject.findIndex((item) => item.id == id);
      console.log(arrayObject[findIndex]);
      return arrayObject[findIndex];
    },

    async getMethodPaymentActive() {
      const response = await this.axios.get(
        Endpoints.excecuteGetMethodPayment.replace(/:userID/, this.user._id)
      );
      const { data } = response;
      this.hasStripeConfig = this.getValueInOrgData(
        data.methods,
        "bank_transfer_payments"
      ).active;
    },
    async fetchOrganizationData(retryCount = 3) {
      let attempts = 0;
      while (attempts < retryCount) {
        try {
          const response = await this.axios.get(Endpoints.users);
          if (response.status === 200 && response.data.user) {
            this.organizationData.forEach((e) => {
              e.value = response.data.user[e.id];
            });
            this.documentsInputs = response.data.user.branchDocForm;
            this.userProfilePicture = response.data.user.profilePicture;
            this.tempData = this.organizationData.map((e) => e.value || "");
            if (response.data.user.bankInformation) {
              this.user.bankInformation = response.data.user.bankInformation;
              return;
            } else {
              throw new Error("BankInformation no está disponible");
            }
          } else {
            throw new Error("Datos de usuario incompletos");
          }
        } catch (error) {
          attempts++;
          if (attempts >= retryCount) {
            return;
          }
        }
      }
    },

    async inputHandler(inputFile) {
      try {
        this.loading();
        if (inputFile.value != null) {
          const formData = new FormData();
          console.log(this.documentsInputs[inputFile.index]);
          formData.append("file", inputFile.value);
          const response = await this.axios.put(
            `/registrationFields/form/branches/docs/${
              this.documentsInputs[inputFile.index]._id
            }`,
            formData
          );

          if (response.status == 200) {
            this.show({
              color: "primary",
              text: "¡Documento cargado exitosamente!",
            });
          }
        }
      } catch (error) {
        this.show({
          color: "error",
          text: "Error al cargar el archivo",
        });
      } finally {
        this.loaded();
      }
    },

    async handleImageUpdate(file) {
      const formData = new FormData();
      formData.append("file", file);
      this.loading();
      const response = await this.axios.put(
        Endpoints.usersUpdateProfilePicture,
        formData
      );
      if (response.status === 200) {
        await this.realoadUserData({ email: this.user.email });
        this.loaded();
        this.show({
          color: "primary",
          text: "¡La imagen se ha actualizado correctamente!",
        });
      } else {
        this.loaded();
        this.show({
          color: "error",
          time: 3000,
          text: "Error al cargar la imagen",
        });
      }
    },
    async connectAccount() {
      this.bankAccountLoader = true;
      const response = await this.axios.post(Endpoints.accountsConnect);
      if (response.status === 200) {
        const { url } = response.data;
        window.location.replace(url);
        this.bankAccountLoader = false;
      }
      this.bankAccountLoader = false;
    },

    async getExpressLink() {
      this.expressLinkLoader = true;
      const response = await this.axios({
        method: "GET",
        url: "/users/accountLink",
      });
      if (response.status === 200) {
        const { url } = response.data;
        window.open(url, "_blank").focus();
      }
      this.expressLinkLoader = false;
    },

    async handleDataUpdate() {
      this.buttonLoader = true;
      let payload = { user: {} };
      this.organizationData.forEach((e) => {
        payload.user[e.id] = e.value;
      });
      const response = await this.axios.put(Endpoints.users, payload);
      if (response.status == 200) {
        this.show({
          color: "success",
          time: 3000,
          text: "¡Datos cargados exitosamente!",
        });
        this.organizationData.forEach((e) => {
          e.value = response.data.user[e.id];
        });
        this.tempData = this.organizationData.map((e) => e.value || "");
        this.disabledButton = true;
      } else {
        this.show({
          color: "error",
          time: 3000,
          text: "Error en la carga de datos",
        });
        this.disabledButton = true;
      }
      this.buttonLoader = false;
    },

    async handleUpdatePwd() {
      const payload = {
        user: {
          oldPassword: this.password[0].value,
          newPassword: this.password[1].value,
        },
      };
      const response = await this.axios.put(
        Endpoints.usersUpdatePassword,
        payload
      );
      if (response.status === 200) {
        this.show({
          color: "primary",
          text: "La contraseña se actualizó correctamente",
        });
        this.closePasswordDialog();
      } else {
        this.show({
          color: "error",
          text: "Verifique la contraseña ingresada!",
        });
      }
    },
  },
};
</script>
<style scoped>
.inputCard {
  border: 2px solid #b2e5fd;
  border-style: dashed;
}

.labelCard {
  position: absolute;
  top: -13px;
  left: 1;
  z-index: 2;
  background-color: white;
  font-weight: 700;
}

.stickDiv {
  height: 80px;
  position: -webkit -sticky;
  position: sticky;
  top: 0;
  background: transparent;
  z-index: 2;
}
</style>

<!-- company: "",
phone: "",
name: "",
firstLastname: "",
secondLastname: "",
email: "",
profilePicture: "", -->